export const ADMIN_ROUTE = '/admin';
export const AUTH_ROUTE = '/auth';
export const GENDERS = {
    'multi' : {
        name: 'Пара',
        code: 'multi'
    },
    'male': {
        name: 'Мужской',
        code: 'male'
    },
    'female': {
        name: 'Женский',
        code: 'female'
    }
}