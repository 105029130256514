import React from 'react';
import style from '../style.module.css';

const Blocker = () => {
  return (
    <div className={style.pc_blocker}>
        Мы пока не сделали дизайн для десктопа, поэтому просим перейти на мобильную версию) 🤷
    </div>
  )
}

export default Blocker