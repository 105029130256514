import React from 'react';
import styles from '../screens/screen.module.css';

const Pointer = () => {
    return (
        <svg className={styles.pointer} width="60px" height="60px" xmlns="http://www.w3.org/2000/svg" viewBox="-98 0 496 496">
            <path d="m251.402344 160.488281c-3.460938-14.335937-16.269532-24.445312-31.015625-24.488281h-25.136719c-3.636719-14.085938-16.316406-23.945312-30.863281-24h-32v-72c0-22.089844-17.910157-40-40-40-22.09375 0-40 17.910156-40 40v125.191406l-33.128907 41.410156c-19.761718 24.761719-24.71875 58.222657-12.992187 87.65625l46.121094 115.285157v78.457031c0 4.417969 3.582031 8 8 8h232c4.417969 0 8-3.582031 8-8v-272c-.050781-28.1875-21.023438-51.957031-48.984375-55.511719zm-219.640625 56.105469 20.625-25.785156v57.191406c0 4.417969 3.582031 8 8 8s8-3.582031 8-8v-208c0-13.253906 10.742187-24 24-24 13.253906 0 24 10.746094 24 24v160c0 4.417969 3.582031 8 8 8s8-3.582031 8-8v-72h32c8.835937 0 16 7.164062 16 16v72c0 4.417969 3.582031 8 8 8s8-3.582031 8-8v-64h24c8.835937 0 16 7.164062 16 16v64c0 4.417969 3.582031 8 8 8s8-3.582031 8-8v-55.199219c18.613281 3.824219 31.976562 20.199219 32 39.199219v184h-218.585938l-44.671875-111.679688c-9.605468-24.082031-5.546875-51.46875 10.632813-71.726562zm36.625 263.40625v-64h216v64zm0 0" />
        </svg>
    )
}

export default Pointer